export default async function convertBase64(file: Blob) {
  return await new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64Result: string = reader.result as string;
      resolve(base64Result);
    };
    reader.onerror = reject;
  });
}
