import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Carousel from 'react-material-ui-carousel';
import Icon from '../../assets/icons';
import SliderItemIcon from './SliderItemIcon';
import './slider.css';
function SwipeableTextMobileStepper({ data }: { data: any }) {
  return (
    <Box className="slidercontainer">
      <Carousel
        height="280px"
        className="mobile-carousel"
        autoPlay={true}
        animation="slide"
        fullHeightHover={false}
        navButtonsProps={{
          style: {
            backgroundColor: 'inherit',
          },
        }}
        indicatorIconButtonProps={{
          style: {
            padding: '0px',
            marginRight: '10px',
            color: '#0099ff',
          },
        }}
        activeIndicatorIconButtonProps={{
          style: {
            color: 'black',
            backgroundColor: '#0099ff',
          },
        }}
        indicatorContainerProps={{
          style: {
            textAlign: 'center',
            position: 'absolute',
            zIndex: '1000',
            bottom: '20px',
          },
        }}
      >
        {data &&
          data.map((item: any, index: any) => (
            <div key={index} className="slider-main">
              <Box className="arrow-image">
                {/* If data has icon key, render the img using supplied icon key */}
                {item.icon ? (
                  <img src={item.icon} alt="arrow" />
                ) : (
                  <SliderItemIcon contentType={item.type} />
                )}
              </Box>
              <Box className="column-box column-box-slider">
                {Array.isArray(item.text) ? (
                  <div>
                    {item.text.map((ele: any, i: number) => {
                      return (
                        <Box key={i} sx={{ display: 'flex' }}>
                          <Box sx={{ mr: 1 }}>
                            <Icon.Checkmark />
                          </Box>
                          <Typography className="slider-typography">
                            {ele}
                          </Typography>
                        </Box>
                      );
                    })}
                  </div>
                ) : (
                  <Box>
                    <Typography className="content-text">
                      {item.text}
                    </Typography>
                  </Box>
                )}
              </Box>
            </div>
          ))}
      </Carousel>
    </Box>
  );
}

export default SwipeableTextMobileStepper;
