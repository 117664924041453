import { EScanModeTypes } from '../static/scanModesMetaData';

export enum EConfidenceLevel {
  CONFIDENT = 'CONFIDENT',
  SEMI_CONFIDENT = 'SEMI_CONFIDENT',
  NOT_CONFIDENT = 'NOT_CONFIDENT',
  UNKNOWN_CONFIDENCE = 'UNKNOWN_CONFIDENCE',
}

export default function getConfidenceLevel(
  confidence: number | null,
  scanMode: EScanModeTypes,
): EConfidenceLevel {
  if (confidence === null || confidence === -1 || confidence === undefined) {
    return EConfidenceLevel.UNKNOWN_CONFIDENCE;
  }

  if (scanMode === EScanModeTypes.TSW) {
    return confidence >= 0.8
      ? EConfidenceLevel.CONFIDENT
      : confidence >= 0.3
        ? EConfidenceLevel.SEMI_CONFIDENT
        : EConfidenceLevel.NOT_CONFIDENT;
  }

  // other scan modes
  return confidence >= 80
    ? EConfidenceLevel.CONFIDENT
    : confidence >= 30
      ? EConfidenceLevel.SEMI_CONFIDENT
      : EConfidenceLevel.NOT_CONFIDENT;
}
