import { type IMeterData } from '../../../services/RequestHandler/types';
import { EScanModeTypes } from '../../../static/scanModesMetaData';
import getConfidence from '../utils/getConfidence';
import getConfidenceCSSClass from '../utils/getConfidenceCSSClass';
import style from './MeterScanResult.module.scss';

const Meter: React.FC<IMeterData> = ({ result }: IMeterData) => {
  const { reading, confidence } = result;

  const confidenceCSSStyle = getConfidenceCSSClass(
    confidence,
    EScanModeTypes.AUTO_ANALOG_DIGITAL_METER,
  );

  return (
    <div>
      <span>Reading</span> <br />
      <span className={style.reading}>{reading || 'N/A'}</span> <br />
      <span>Confidence:</span>{' '}
      <span className={confidenceCSSStyle}>
        <b>
          {getConfidence(confidence, EScanModeTypes.AUTO_ANALOG_DIGITAL_METER)}
        </b>
      </span>
    </div>
  );
};

Meter.defaultProps = {
  result: {
    reading: null,
    confidence: -1,
    outline: '',
    reportedText: '',
    scanMode: EScanModeTypes.AUTO_ANALOG_DIGITAL_METER,
  },
  sdkVersion: '',
};

export default Meter;
