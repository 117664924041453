interface ISubTitle {
  title: string;
  link: string;
}

export interface IFooterLinksMetaData {
  id: string;
  title: string;
  link: string;
  subTitles: ISubTitle[];
}

const footerLinksMetaData: IFooterLinksMetaData[] = [
  {
    id: '1',
    title: 'GET IN CONTACT',
    link: 'https://anyline.com/request/contact',
    subTitles: [
      {
        title: 'Try our SDK',
        link: 'https://anyline.com/request/sdk',
      },
      {
        title: 'Contact Us',
        link: 'https://anyline.com/request/contact',
      },
      {
        title: 'Book a meeting',
        link: 'https://ocr.anyline.com/appointment',
      },
    ],
  },
  {
    id: '2',
    title: 'RESOURCES',
    link: 'https://anyline.com/resources',
    subTitles: [
      {
        title: 'Support',
        link: 'https://anyline.atlassian.net/servicedesk/customer/portal/2/group/-1',
      },
      {
        title: 'FAQ',
        link: 'https://anyline.com/faq',
      },
      {
        title: 'Newsletter',
        link: 'https://ocr.anyline.com/newsletter',
      },
      {
        title: 'Example Booklet',
        link: 'https://anyline.com/example-sheets',
      },
    ],
  },
  {
    id: '3',
    title: 'COMPANY',
    link: 'https://anyline.com/',
    subTitles: [
      {
        title: 'About',
        link: 'https://anyline.com/about',
      },
      {
        title: 'Jobs',
        link: 'https://anyline.com/jobs',
      },
      {
        title: 'Press and Media',
        link: 'https://anyline.com/press',
      },
      {
        title: 'Partners',
        link: 'https://anyline.com/partners',
      },
    ],
  },
  {
    id: '4',
    title: 'ANYLINE PLATFORM',
    link: 'https://anyline.com/',
    subTitles: [
      {
        title: 'Solutions',
        link: 'https://anyline.com/products',
      },
      {
        title: 'Pricing',
        link: 'https://anyline.com/pricing',
      },
      {
        title: 'For Developers',
        link: 'https://anyline.com/developer',
      },
      {
        title: 'How to get started',
        link: 'https://anyline.com/how-to-get-started',
      },
    ],
  },
  {
    id: '5',
    title: '',
    link: '',
    subTitles: [
      {
        title: 'Privacy Policy',
        link: 'https://anyline.com/imprint-and-legal',
      },
      {
        title: 'Imprint',
        link: 'https://anyline.com/imprint-and-legal',
      },
    ],
  },
];

export default footerLinksMetaData;
