const LightBulb = () => (
  <svg
    id="Group_127"
    data-name="Group 127"
    xmlns="http://www.w3.org/2000/svg"
    width="24.782"
    height="30.734"
    viewBox="0 0 24.782 30.734"
  >
    <path
      id="Path_93"
      data-name="Path 93"
      d="M17.97,30.71a.859.859,0,0,1-.81-.58.588.588,0,0,0-.15-.24.558.558,0,0,0-.42-.13H8.16a.572.572,0,0,0-.31.11.7.7,0,0,0-.26.32.852.852,0,0,1-1.58-.64,2.36,2.36,0,0,1,2.12-1.48h8.41a2.273,2.273,0,0,1,1.6.56,2.235,2.235,0,0,1,.64.98.841.841,0,0,1-.81,1.11Z"
      transform="translate(0.002 0.009)"
      fill="#09f"
    />
    <path
      id="Path_94"
      data-name="Path 94"
      d="M18.2,26.46H6.69a.849.849,0,0,1-.85-.85V22.9A12.377,12.377,0,0,1,0,12.14,12.574,12.574,0,0,1,11.88,0a12.392,12.392,0,0,1,7.17,22.83V25.6a.839.839,0,0,1-.25.6.857.857,0,0,1-.6.25ZM7.54,24.76h9.81v-2.4a.844.844,0,0,1,.42-.73,10.744,10.744,0,0,0,5.31-9.24A10.7,10.7,0,0,0,11.95,1.71,10.832,10.832,0,0,0,1.7,12.18a10.659,10.659,0,0,0,5.41,9.5.837.837,0,0,1,.43.74v2.34Z"
      transform="translate(0.002 0.009)"
      fill="#09f"
    />
  </svg>
);

export default LightBulb;
