import React from 'react';
import Icon from '../../../assets/icons';
import {
  type BarcodeData,
  type ITSBarcodeData,
} from '../../../services/RequestHandler/types';

import style from '../ScanResults.module.scss';
import barcodeStyle from './BarcodeScanResult.module.scss';

const BarcodeScanResult: React.FC<ITSBarcodeData> = ({ barcodes }) => {
  const renderDetails = (barcodeData: BarcodeData) => {
    const { value, format } = barcodeData;
    return (
      <div>
        <div className={barcodeStyle.readingDetailsCheckMark}>
          <div>
            <Icon.Checkmark />
          </div>
          <div>Symbology</div>
        </div>
        <div className={barcodeStyle.readingDetailsInd}>
          <div className={barcodeStyle.readingKey}>Reading:&nbsp;</div>
          <div
            className={`${barcodeStyle.readingValue} ${style.blue}`}
            data-testid={`BarcodeScanResult-${format}`}
          >
            {format || 'N/A'}
          </div>
        </div>
        <div className={barcodeStyle.readingDetailsCheckMark}>
          <div>
            <Icon.Checkmark />
          </div>
          <div>Value</div>
        </div>
        <div className={barcodeStyle.readingDetailsInd}>
          <div className={barcodeStyle.readingKey}>Reading:&nbsp;</div>
          <div
            className={`${barcodeStyle.readingValue} ${style.blue}`}
            data-testid={`BarcodeScanResult-${format}`}
          >
            {value || 'N/A'}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={barcodeStyle.readingDetails}>
      {barcodes.map((barcode: BarcodeData, index) => (
        <div key={`barcodeResult-${index}`} className={barcodeStyle.wrapper}>
          {renderDetails(barcode)}
        </div>
      ))}
    </div>
  );
};

BarcodeScanResult.defaultProps = {
  barcodes: [
    {
      coordinates: null,
      format: null,
      value: null,
    },
  ],
};

export default BarcodeScanResult;
