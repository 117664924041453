import { type EScanModeTypes } from '../../../static/scanModesMetaData';
import getConfidenceLevel, {
  EConfidenceLevel,
} from '../../../utils/getConfidenceLevel';
import style from '../ScanResults.module.scss';

export default function getConfidenceCSSClass(
  confidence: number | null,
  scanMode: EScanModeTypes,
): string {
  let confidenceCSSClass: string = style.blue;

  const confidenceLevel: EConfidenceLevel = getConfidenceLevel(
    confidence,
    scanMode,
  );

  switch (confidenceLevel) {
    case EConfidenceLevel.CONFIDENT:
      confidenceCSSClass = style.green;
      break;
    case EConfidenceLevel.SEMI_CONFIDENT:
      confidenceCSSClass = style.orange;
      break;
    case EConfidenceLevel.NOT_CONFIDENT:
      confidenceCSSClass = style.red;
      break;
    default:
      confidenceCSSClass = style.blue;
  }

  return confidenceCSSClass;
}
