import { Card, CardActionArea, CardContent, Typography } from '@mui/material';
import { useContext } from 'react';
import { SidebarContext } from '../../contexts/SidebarContext';
import './CardLayout.css';

interface cardData {
  dataSet: any;
  width?: string;
  height?: string;
}

const CardLayout = ({ dataSet }: cardData) => {
  const { cardId, setCardId }: any = useContext(SidebarContext);

  const handleClick = () => {
    if (dataSet.id === cardId) {
      // Reset the card ID if it is already selected
      setCardId(null);
    } else {
      // Set the selected card ID
      setCardId(dataSet.id);
    }
  };

  return (
    <Card className={dataSet.id === cardId ? 'afterClick' : 'cardStyle'}>
      <CardActionArea onClick={handleClick}>
        <CardContent className="card-content">
          <Typography
            gutterBottom
            variant="subtitle2"
            component="div"
            className="titleStyle"
          >
            {dataSet.title}
          </Typography>
          <Typography
            variant="caption"
            color="text.secondary"
            className="cardContent"
          >
            {dataSet.description}
          </Typography>
          {dataSet.id === cardId && (
            <div className="rightIcon">
              <img src="/group80.svg" alt="rightIcon" />
            </div>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default CardLayout;
