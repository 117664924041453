import React, { type ReactNode } from 'react';
import '../styles/Layout.css';

interface LayoutProps {
  sidebar: ReactNode;
  mainContent: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ sidebar, mainContent }) => {
  return (
    <div className="layout">
      <div className="sidebar sidebar-isdesktop">{sidebar}</div>
      <div className="main-content">{mainContent}</div>
    </div>
  );
};

export default Layout;
