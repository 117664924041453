import React, { useContext } from 'react';
import { SidebarContext } from '../contexts/SidebarContext';
import '../styles/Read.css';

const Read = () => {
  const { currentProgress }: any = useContext(SidebarContext);

  const CurrentProgress1 = () => {
    return <img className="read-image" src="/uploading.svg" alt="Second" />;
  };
  const CurrentProgress2 = () => {
    return <img className="read-image" src="/read1.svg" alt="Second" />;
  };
  const CurrentProgress3 = () => {
    return <img className="read-image" src="/read2.svg" alt="Second" />;
  };
  const CurrentProgress4 = () => {
    return <img className="read-image" src="/complete.svg" alt="Second" />;
  };
  return (
    <div id="para" className="read-container">
      <div className="read-main">
        <img
          className="read-main-img"
          src="/group115-processing.gif"
          alt="First"
        />
        {currentProgress === 1 ? (
          <CurrentProgress1 />
        ) : currentProgress === 2 ? (
          <CurrentProgress2 />
        ) : currentProgress === 3 ? (
          <CurrentProgress3 />
        ) : (
          <CurrentProgress4 />
        )}
      </div>
    </div>
  );
};

export default Read;
