import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';

export default function SideBarFooter() {
  return (
    <Grid container className="sidebarFooter">
      <Grid item xs={12}>
        <Typography variant="caption" className="sidebar-text">
          To test it within your own application,
          <br /> you can also request a&nbsp;
          <a className="sidebar-footer" href="https://anyline.com/request/sdk">
            free 30-day SDK trial.
          </a>
        </Typography>
      </Grid>
    </Grid>
  );
}
