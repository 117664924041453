import { useContext } from 'react';
import { SidebarContext } from '../../contexts/SidebarContext';
import FirstBanner from './../FirstBanner';
import Read from './../Read';
import StepProgress from './../StepProgress';
import UploadRead from './../UploadRead';
import ViewResults from './../ViewResults';
import Sidebar from './../sidebar/Sidebar';
import style from './MainContent.module.scss';

const MainContent = () => {
  const { activeStep }: any = useContext(SidebarContext);

  return (
    <div className={style.wrapper}>
      <StepProgress activeStep={activeStep} />
      <div className="sidebar sidebar-ismobile">
        <Sidebar />
      </div>
      {activeStep === 1 && <FirstBanner />}
      {activeStep === 2 && <UploadRead />}
      {activeStep === 3 && <Read />}
      {activeStep === 4 && <ViewResults />}
    </div>
  );
};

export default MainContent;
