import Dialog from '@mui/material/Dialog';
import '../../styles/Common.scss';
import './Modal.css';
interface IProps {
  open: boolean;
  children: React.ReactNode;
}
const Modal = ({ open, children }: IProps) => {
  return (
    <div className="consent-modal">
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="modal-dialog"
      >
        {children}
      </Dialog>
    </div>
  );
};

export default Modal;
