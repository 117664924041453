const blobToUint8Array = async (blob: Blob): Promise<Uint8Array> => {
  return await new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function (event) {
      if (!event.target) {
        reject(new Error('Failed to read the blob as ArrayBuffer'));
        return;
      }
      const arrayBuffer = event.target.result as ArrayBuffer;
      const uint8Array = new Uint8Array(arrayBuffer);
      resolve(uint8Array);
    };
    reader.onerror = function () {
      reject(new Error('Failed to convert blob to Uint8Array'));
    };
    reader.readAsArrayBuffer(blob);
  });
};

export default blobToUint8Array;
