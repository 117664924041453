import { ExpandLess, ExpandMore } from '@mui/icons-material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuIcon from '@mui/icons-material/Menu';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import { Collapse } from '@mui/material';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import { styled, useTheme } from '@mui/material/styles';
import * as React from 'react';
import './drawer.css';
const drawerWidth = '100%';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

export default function PersistentDrawerRight() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [open4, setOpen4] = React.useState(false);
  const [open5, setOpen5] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const DrawerHeaderComponent = () => {
    return (
      <DrawerHeader
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          minHeight: '79px',
        }}
      >
        <div style={{ marginLeft: '10px' }}>
          {' '}
          <a href="https://anyline.com/">
            <img alt="" src="./AIcon.png" />
          </a>
        </div>
        <div className="drawer-contact">
          <div className="drawer-arrow">
            <TrendingFlatIcon />
          </div>
          <div className="drawer-contact-button">
            <a href="https://anyline.com/request/contact">CONTACT US</a>
          </div>
        </div>
        <div>
          <IconButton
            onClick={handleDrawerClose}
            sx={{ color: '#F0F0F0', marginRight: '10px' }}
          >
            {theme.direction === 'rtl' ? (
              <ChevronLeftIcon />
            ) : (
              <img alt="close" width="100%" height="100%" src="./cross.svg" />
            )}
          </IconButton>
        </div>
      </DrawerHeader>
    );
  };
  const DrawerList = () => {
    return (
      <List
        sx={{ width: '100%', bgcolor: 'background.paper' }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        <div className="list-container">
          <div
            className="list-item-container"
            onClick={() => {
              setOpen2(!open2);
            }}
          >
            <ListItemText className="list-item-text" primary="RESOURCES" />
            {open2 ? (
              <ExpandLess fontSize="large" />
            ) : (
              <ExpandMore fontSize="large" />
            )}
          </div>
          <Collapse in={open2} timeout="auto" unmountOnExit>
            <List component="div" className="submenu-list" disablePadding>
              <a
                href="https://anyline.atlassian.net/servicedesk/customer/portal/2/group/-1"
                className="drawer-item-link"
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Support" />
                </ListItemButton>
              </a>
              <a href="https://anyline.com/faq" className="drawer-item-link">
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="FAQ" />
                </ListItemButton>
              </a>
              <a
                href="https://ocr.anyline.com/newsletter"
                className="drawer-item-link"
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Newsletter" />
                </ListItemButton>
              </a>
              <a
                href="https://anyline.com/example-sheets"
                className="drawer-item-link"
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Example Booklet" />
                </ListItemButton>
              </a>
            </List>
          </Collapse>
          <Divider className="drawer-hr" />
        </div>
        <div className="list-container">
          <div
            className="list-item-container"
            onClick={() => {
              setOpen3(!open3);
            }}
          >
            <ListItemText className="list-item-text" primary="COMPANY" />
            {open3 ? (
              <ExpandLess fontSize="large" />
            ) : (
              <ExpandMore fontSize="large" />
            )}
          </div>
          <Collapse in={open3} timeout="auto" unmountOnExit>
            <List component="div" className="submenu-list" disablePadding>
              <a href="https://anyline.com/about" className="drawer-item-link">
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="About" />
                </ListItemButton>
              </a>
              <a href="https://anyline.com/jobs" className="drawer-item-link">
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Jobs" />
                </ListItemButton>
              </a>
              <a href="https://anyline.com/press" className="drawer-item-link">
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Press and Media" />
                </ListItemButton>
              </a>
              <a
                href="https://anyline.com/partners"
                className="drawer-item-link"
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Partners" />
                </ListItemButton>
              </a>
            </List>
          </Collapse>
          <Divider className="drawer-hr" />
        </div>
        <div className="list-container">
          <div
            className="list-item-container"
            onClick={() => {
              setOpen4(!open4);
            }}
          >
            <ListItemText
              className="list-item-text"
              primary="ANYLINE PLATFORM"
            />
            {open4 ? (
              <ExpandLess fontSize="large" />
            ) : (
              <ExpandMore fontSize="large" />
            )}
          </div>
          <Collapse in={open4} timeout="auto" unmountOnExit>
            <List component="div" className="submenu-list" disablePadding>
              <a
                href="https://anyline.com/products"
                className="drawer-item-link"
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Solutions" />
                </ListItemButton>
              </a>
              <a
                href="https://anyline.com/pricing"
                className="drawer-item-link"
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Pricing" />
                </ListItemButton>
              </a>
              <a
                href="https://anyline.com/developer"
                className="drawer-item-link"
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="For Developers" />
                </ListItemButton>
              </a>
              <a
                href="https://anyline.com/how-to-get-started"
                className="drawer-item-link"
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="How to get started" />
                </ListItemButton>
              </a>
            </List>
          </Collapse>
          <Divider className="drawer-hr" />
        </div>
        <div className="list-container">
          <div
            className="list-item-container"
            onClick={() => {
              setOpen5(!open5);
            }}
          >
            {open5 ? (
              <ExpandLess fontSize="large" />
            ) : (
              <ExpandMore fontSize="large" />
            )}
          </div>
          <Collapse in={open5} timeout="auto" unmountOnExit>
            <List component="div" className="submenu-list" disablePadding>
              <a
                href="https://anyline.com/request/sdk"
                className="drawer-item-link"
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Try our SDK" />
                </ListItemButton>
              </a>
              <a
                href="https://anyline.com/request/contact"
                className="drawer-item-link"
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Contact Us" />
                </ListItemButton>
              </a>
              <a
                href="https://ocr.anyline.com/appointment"
                className="drawer-item-link"
              >
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="Book a meeting" />
                </ListItemButton>
              </a>
            </List>
          </Collapse>
          <Divider className="drawer-hr" />
        </div>
      </List>
    );
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="end"
          onClick={handleDrawerOpen}
          sx={{ ...(open && { display: 'none' }) }}
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            backgroundColor: '#212121',
            color: '#F0F0F0',
          },
        }}
        className="drawer-main"
        variant="persistent"
        anchor="right"
        open={open}
      >
        <DrawerHeaderComponent />
        <DrawerList />
      </Drawer>
    </Box>
  );
}
