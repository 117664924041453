import { ETabs } from '../components/ViewResults';
import { type EScanModeTypes } from '../static/scanModesMetaData';
import formatDataToStructuredText from './formatDataToStructuredText';

const formatData = (data: any, activeTab: ETabs, scanMode: EScanModeTypes) => {
  if (activeTab === ETabs.DATA)
    return formatDataToStructuredText(data, scanMode);

  return JSON.stringify(data, null, 2);
};

export default formatData;
