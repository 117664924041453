import { Box, Typography } from '@mui/material';
import { useContext } from 'react';
import Icon from '../assets/icons';
import '../components/slider/slider.css';
import { SidebarContext } from '../contexts/SidebarContext';
import { EContentTypes, type IContent } from '../static/scanModesMetaData';
import '../styles/Common.scss';
import '../styles/SecondBanner.css'; // Import the CSS file
import SwipeableTextMobileStepper from './slider/slider';

const SecondBanner = () => {
  const {
    selectedCard: { content },
  }: any = useContext(SidebarContext);

  const findDescription = (type: EContentTypes) => {
    const index = content.findIndex((e: IContent) => e.type === type);
    // found description type in content, return description text
    if (index !== -1) {
      return content[index].text;
    }
    // cannot find type in the content
    return [];
  };

  const shortDescription = findDescription(EContentTypes.ShortDescription);
  const benefits = findDescription(EContentTypes.Benefits);
  const features = findDescription(EContentTypes.Features);

  const CardComponent = () => {
    return (
      <>
        {/* Mobile display */}
        <div className="BannerMobile">
          <SwipeableTextMobileStepper data={content} />
        </div>

        {/* Desktop display */}
        <div className="BannerDesktop2">
          {/* Short Description */}
          <Box className="BannerDesktop2-head-box">
            <Box sx={{ mb: 3 }}>
              <Icon.LightBulb />
            </Box>
            <Box>
              <Typography className="BannerDesktop2-typography2">
                {shortDescription || ''}
              </Typography>
            </Box>
          </Box>

          {/* Benefits */}
          <Box className="BannerDesktop2-head-box" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ mb: 3 }}>
              <Icon.Thunder />
            </Box>
            {benefits.length > 0 ? (
              benefits.map((e: string) => {
                return (
                  <Box sx={{ display: 'flex' }} key={e}>
                    <Box sx={{ mr: 1 }}>
                      <Icon.Checkmark />
                    </Box>
                    <Typography className="BannerDesktop2-typography">
                      {e}
                    </Typography>
                  </Box>
                );
              })
            ) : (
              <></>
            )}
          </Box>

          {/* Features */}
          <Box className="BannerDesktop2-head-box">
            <Box sx={{ mb: 3 }}>
              <Icon.Person />
            </Box>
            {features.length > 0 ? (
              features.map((e: string) => {
                return (
                  <Box sx={{ display: 'flex' }} key={e}>
                    <Box sx={{ mr: 1 }}>
                      <Icon.Checkmark />
                    </Box>
                    <Typography className="BannerDesktop2-typography">
                      {e}
                    </Typography>
                  </Box>
                );
              })
            ) : (
              <></>
            )}
          </Box>
        </div>
      </>
    );
  };

  return (
    <>
      <Box className="SecondBanner-container">
        <Box className="SecondBanner-main">
          <Box className="SecondBanner-main2">
            <Box className="SecondBanner-content">
              <CardComponent />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SecondBanner;
