import Pica from 'pica';

// Function to downsize an image from a Blob and return it as a Blob
export async function downsizeImageFromBlob(imageBlob: Blob): Promise<Blob> {
  const pica = new Pica();

  // Create an HTML image element
  const img = new Image();
  const imgURL = URL.createObjectURL(imageBlob);
  img.src = imgURL;

  // Wait for the image to load
  await new Promise((resolve, reject) => {
    img.onload = resolve;
    img.onerror = reject;
  });

  // Create an off-screen canvas
  const canvas = document.createElement('canvas');
  canvas.width = img.width;
  canvas.height = img.height;

  const resizedCanvas = await pica.resize(img, canvas);

  // Convert the resized canvas to a Blob (JPEG format)
  const resizedBlob = await pica.toBlob(resizedCanvas, 'image/jpeg', 0.8);

  // Check if the file size is under 1MB, and reduce quality if needed
  let quality = 0.8;
  let fileSize = resizedBlob.size;

  while (fileSize > 1_000_000 && quality > 0.1) {
    quality -= 0.1;
    const reducedBlob = await pica.toBlob(resizedCanvas, 'image/jpeg', quality);
    fileSize = reducedBlob.size;
    if (fileSize <= 1_000_000) {
      return reducedBlob;
    }
  }

  return resizedBlob;
}
