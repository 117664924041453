import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useContext, useEffect } from 'react';
import { SidebarContext } from '../../contexts/SidebarContext';
import Modal from '../modal/Modal';
import './Sidebar.css';
import UserConsent from '../UserConsent/UserConsent';
import SideBarFooter from './components/SideBarFooter/SideBarFooter';
import TakeOrUploadAPicture from './components/TakeOrUploadAPicture/TakeOrUploadAPicture';
import SideBarImageBox from './components/SideBarImageBox/SideBarImageBox';
import { useProcessImage } from './hooks/useProcessImage';
import SideBarTiles from './components/SideBarTiles/SideBarTiles';
import convertBase64 from './utils/convertBase64';

const Sidebar = () => {
  const {
    isClicked,
    cardId,
    file,
    setActiveStep,
    openModal,
    setPreviewImage,
    setCookieConcent,
    activeStep,
    selectedCard,
  }: any = useContext(SidebarContext);

  const { processImage } = useProcessImage();

  useEffect(() => {
    const consentGiven = sessionStorage.getItem('opened');
    if (consentGiven) {
      setCookieConcent(true);
    }
  }, []);

  const handleCallback = async (file: Blob) => {
    if (file) {
      setActiveStep(3);
      localStorage.removeItem('img');
      const base64 = await convertBase64(file);
      const imageArray = base64.split(',');
      const imageData: string = imageArray[1];
      setPreviewImage(base64);
      await processImage(imageData);
    }
  };

  return (
    <div className="sidebarcontainer">
      {activeStep === 3 ? <div className="sidebarmain"></div> : null}
      <Grid
        container
        rowSpacing={{ xs: 1, sm: 2, md: 2 }}
        columnSpacing={{ xs: 1, sm: 2, md: 2 }}
        className="mainContainer"
      >
        <Grid item xs={12}>
          <Typography variant="h6" className="mainHeading">
            Pick the mode you wish to try
          </Typography>
        </Grid>
        <SideBarTiles />

        {isClicked && cardId && selectedCard && (
          <>
            <TakeOrUploadAPicture handleCallback={handleCallback} />
            <Box className="sidebar-text-container">
              <Typography className="sidebar-text">OR</Typography>
            </Box>
            <Box className="sidebar-sample-container" style={{ width: '100%' }}>
              <Typography className="sidebar-sample-typography">
                Use any of the following sample images
              </Typography>
            </Box>
            <SideBarImageBox handleCallback={handleCallback} />
          </>
        )}
      </Grid>
      <SideBarFooter />
      <Modal open={openModal}>
        <UserConsent
          onAgree={async () => {
            await handleCallback(file);
          }}
        />
      </Modal>
    </div>
  );
};

export default Sidebar;
