import { measureBlur, filters } from './preProcessImage';

const CONTRAST_THRESHOLD = 35;
const NUMBER_EDGES_THRESHOLD = 4000;
const AVERAGE_EDGES_WIDTH_THRESHOLD = 5;
const AVERAGE_EDGES_WIDTH_PERC_THRESHOLD = 0.6;

const undefinedMetrics = {
  isBlurDetected: undefined,
  isContrastLow: undefined,
};

export default async function detectMetrics(fileContent: string): Promise<{
  isBlurDetected?: boolean;
  isContrastLow?: boolean;
}> {
  return await new Promise((resolve, reject) => {
    if (typeof fileContent !== 'string') {
      resolve(undefinedMetrics);
      return;
    }

    const img = new Image();
    img.src = fileContent;

    img.onload = () => {
      try {
        const imgData = convertToImageData(img);
        if (!imgData) {
          resolve(undefinedMetrics);
          return;
        }

        const blurResult = measureBlur(imgData);

        const isBlurDetected =
          blurResult.num_edges < NUMBER_EDGES_THRESHOLD ||
          blurResult.avg_edge_width > AVERAGE_EDGES_WIDTH_THRESHOLD ||
          blurResult.avg_edge_width_perc > AVERAGE_EDGES_WIDTH_PERC_THRESHOLD;

        const grayData = filters.convertToGrayscale(imgData);

        // Perform contrast detection
        const contrastValue = filters.computeContrast(grayData);

        const isContrastLow = contrastValue < CONTRAST_THRESHOLD;

        resolve({ isBlurDetected, isContrastLow });
      } catch (error) {
        reject(error);
      }
    };

    img.onerror = (error) => {
      reject(error);
    };
  });
}

// Convert to imageData
function convertToImageData(img: HTMLImageElement): ImageData | undefined {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  if (!ctx) {
    return undefined;
  }
  const fixedWidth = 800;
  const scaleFactor = fixedWidth / img.width;
  canvas.width = fixedWidth;
  canvas.height = img.height * scaleFactor;
  ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
  const imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);
  return imgData;
}
