const convertDOT = (dot: string | null) => {
  // Insights API requires tin number in format DOTXXXXYYYY1234
  // Cloud API return tin number in format XXXX YYYY 1234
  if (dot !== null) {
    return dot.startsWith('DOT')
      ? dot.split(' ').join('')
      : `DOT${dot.split(' ').join('')}`;
  }
  return 'DOT';
};

export default convertDOT;
