import { Box, Grid } from '@mui/material';
import { useContext } from 'react';
import { SidebarContext } from '../contexts/SidebarContext';
import '../styles/Common.scss';
import SecondBanner from './SecondBanner';
import { EScanModeTypes } from '../static/scanModesMetaData';

const UploadRead = () => {
  const {
    cardId,
    selectedCard,
    endpointID,
    setEndpointID,
    gcfSlug,
    setGcfSlug,
  }: any = useContext(SidebarContext);

  const ImageComponent = () => {
    return (
      <Grid container justifyContent="center" spacing={2} sx={{ mt: 5, p: 3 }}>
        <Grid
          className="upload-mobile-container"
          item
          xs={10}
          md={4}
          sx={{ p: 0 }}
        >
          <div className="upload-mobile-main">
            <img
              alt=""
              className="upload-mobile-image1"
              src="./iphonefreame.svg"
            />
            <img
              alt=""
              className="upload-mobile-image2"
              src="./images/Meter-Iphone.gif"
            />
          </div>
        </Grid>
        <Grid
          container
          item
          xs={10}
          md={6}
          alignItems="center"
          direction="column"
          justifyContent="center"
        >
          <Grid item className="heading-black">
            <span className="blue">Enhance</span> Your Customer’s{' '}
            <span className="blue">Meter Reading Experience</span> with Instant
            Messaging or Chatbot Integration
          </Grid>
          <Grid item className="description-black" sx={{ mt: 3 }}>
            Anyline Cloud API enables you to{' '}
            <span className="bold">accurately capture</span> meter information
            in <span className="bold">real-time</span> through image uploads
            onto messaging services like Whatsapp, chatbots, websites, or web
            apps, giving your customers a smoother experience.
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <div className="upload-container">
      <Grid
        container
        justifyContent="center"
        spacing={2}
        sx={{ mt: { xs: 0, sm: 5 } }}
      >
        {selectedCard.scanMode === EScanModeTypes.TSW &&
        process.env.REACT_APP_IS_MANUAL_MODEL_SELECTION_ENABLED === 'true' ? (
          <Grid item xs={12} md={10} xl={8}>
            <Box className="blue-heading">{selectedCard.title}</Box>
            <Box sx={{ mt: 1 }} className="heading-description">
              {selectedCard.useCase}
            </Box>
            <Box className="blue-heading">
              <input
                className="heading-description"
                type="text"
                value={endpointID}
                onChange={(e) => {
                  setEndpointID(e.target.value);
                }}
                placeholder="Endpoint ID"
              />
              <Box className="blue-heading">
                <input
                  className="heading-description"
                  type="text"
                  value={gcfSlug}
                  onChange={(e) => {
                    setGcfSlug(e.target.value);
                  }}
                  placeholder="GCF Slug"
                />
              </Box>
            </Box>
            <SecondBanner />
          </Grid>
        ) : (
          <Grid item xs={12} md={10} xl={8}>
            <Box className="blue-heading">{selectedCard.title}</Box>
            <Box sx={{ mt: 1 }} className="heading-description">
              {' '}
              {selectedCard.useCase}
            </Box>
            <SecondBanner />
          </Grid>
        )}
      </Grid>
      {cardId === '1' || cardId === '2' ? <ImageComponent /> : null}
    </div>
  );
};

export default UploadRead;
