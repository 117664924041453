import { Box, Typography } from '@mui/material';
import '../components/slider/slider.css';
import '../styles/Common.scss';
import '../styles/FirstBanner.css';
import SwipeableTextMobileStepper from './slider/slider';

const data = [
  {
    text: 'Anyline Cloud API enables you to send images from any source and receive results back instantly.',
    icon: '/group5.svg',
  },
  {
    text: 'You get to choose how the data is captured, whether that is via an app, a website, a chatbot, or a messaging service.',
    icon: '/group3.svg',
  },
  {
    text: 'Select from the available scan modes or get in touch to let us know what data you would like to capture (license plate and VIN coming soon).',
    icon: '/group4.svg',
  },
];

const FirstBanner = () => {
  const FirstBannerCard = () => {
    return (
      <>
        {data.map((item, index) => (
          <Box key={index} className="column-box BannerDesktop1">
            <Box className="arrow-image">
              <img src={item.icon} alt="arrow" />
            </Box>
            <Box>
              <Typography className="content-text">{item.text}</Typography>
            </Box>
          </Box>
        ))}
      </>
    );
  };
  return (
    <>
      <Box className="banner">
        <div className="banner-container">
          <Box className="banner-box">
            <Box className="content-box">
              <Box className="content-row">
                <div className="BannerMobile">
                  <SwipeableTextMobileStepper data={data} />
                </div>
                <FirstBannerCard />
              </Box>
            </Box>
          </Box>
        </div>
      </Box>
    </>
  );
};

export default FirstBanner;
