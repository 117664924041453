import Icon from '../../assets/icons';
import { EContentTypes } from '../../static/scanModesMetaData';

const SliderItemIcon = ({ contentType }: { contentType: EContentTypes }) => {
  const IconToRender = () => {
    switch (contentType) {
      case EContentTypes.ShortDescription:
        return <Icon.LightBulb />;
      case EContentTypes.Benefits:
        return <Icon.Thunder />;
      case EContentTypes.Features:
        return <Icon.Person />;
      default:
        return <Icon.LightBulb />;
    }
  };

  return <IconToRender />;
};

export default SliderItemIcon;
