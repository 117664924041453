import { useEffect, useState } from 'react';
import useLazyRequest from '../../hooks/useLazyRequest';
import RestAPI from '../../services/RequestHandler/RestAPIList';
import {
  type ITSWData,
  type ITireRecall,
} from '../../services/RequestHandler/types';
import css from './TireRecall.module.css';
import Modal from '../modal/Modal';
import { Typography } from '@mui/material';
import convertDOT from '../../utils/convertDOT';
import Icon from '../../assets/icons';

const TireRecallComponent: React.FC<Readonly<ITSWData>> = ({
  dot,
}: ITSWData) => {
  const { call: getTireRecallInfo } = useLazyRequest(RestAPI.TireRecall.get);
  const [recalls, setRecalls] = useState<ITireRecall[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      if (dot) {
        try {
          const tinValue = convertDOT(dot);
          const data = await getTireRecallInfo(tinValue);
          setRecalls(data.recalls);
        } catch (err) {
          setError('An error occurred. Tap to retry.');
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, []);

  const handleRetry = () => {
    setLoading(true);
    setError(null);
    const tinValue = convertDOT(dot);
    getTireRecallInfo(tinValue)
      .then((data) => {
        setRecalls(data.recalls);
      })
      .catch(() => {
        setError('An error occurred. Tap to retry.');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  if (loading) {
    return <></>;
  }

  return (
    <div className={css.tireRecallContainer}>
      {/* Error state */}
      {error && (
        <div className={css.errorMessage}>
          <div className={css.recallIcon}>
            <Icon.Alert />
          </div>
          <div className={css.recallContext}>
            <p className={css.recallTitle}>Tire Recall & Safety Check</p>
            <p>An error occured.</p>
            <p className={css.clickableText} onClick={handleRetry}>
              Tap to retry.
            </p>
          </div>
        </div>
      )}

      {/* Success with no new information */}
      {!error && recalls.length === 0 && (
        <div className={css.noInfoMessage}>
          <div className={css.recallIcon}>
            <Icon.Cloud />
          </div>
          <div className={css.recallContext}>
            <p className={css.recallTitle}>Tire Recall & Safety Check</p>
            <p>No new information surfaced in the check.</p>
          </div>
        </div>
      )}

      {/* Success with recalls, click to view details */}
      {!error && recalls.length > 0 && (
        <div className={css.recallMessage}>
          <div className={css.recallIcon}>
            <Icon.Alert />
          </div>
          <div className={css.recallContext}>
            <p className={css.recallTitle}>Tire Recall & Safety Check</p>
            <p>Recall Alert!</p>
            <p className={css.clickableText} onClick={toggleModal}>
              Tap here to get more information.
            </p>
          </div>
        </div>
      )}

      {/* Modal for detailed recall information */}
      <Modal open={showModal}>
        <div className={css.wrapper}>
          <div className={css.descriptionWrapper}>
            <Icon.Alert fill="#FEB302" />
            <h2>This tire has been recalled by:</h2>
            <ul>
              {recalls.map((recall) => (
                <li key={recall.recallId}>
                  {recall.manufacturerName} since{' '}
                  {new Date(recall.recallDate).toLocaleDateString()}
                </li>
              ))}
            </ul>
            <p>
              Please contact the manufacturer for guidance on returning or
              replacing the affected tire.
            </p>
          </div>
          <div className={css.buttonWrapper}>
            <button
              onClick={() => {
                setShowModal(false);
              }}
              className={css.primaryButton}
            >
              <Typography variant="subtitle2">Close</Typography>
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default TireRecallComponent;
