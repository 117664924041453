import jwt_decode, { type JwtPayload } from 'jwt-decode';

export const setTokenToLocalStorage = (token: string) => {
  localStorage.setItem('token', JSON.stringify(token));
};

export const getTokenFromLocalStorage = () => {
  const tokenStr = localStorage.getItem('token');
  return tokenStr ? JSON.parse(tokenStr) : null;
};

export const isTokenExpired = (token: string) => {
  const decodedToken = jwt_decode<JwtPayload>(token);
  if (!decodedToken?.exp) {
    return true;
  }
  const currentTimestamp = Math.floor(Date.now() / 1000); // Convert to seconds
  return decodedToken.exp <= currentTimestamp;
};
