const RestAPIHandler = {
  send: async <T>(url: string, request?: RequestInit): Promise<T> => {
    const response = await fetch(url, request);

    if (response.status !== 400 && response.status !== 200) {
      throw new Error('Something went wrong');
    }

    const data: T = await response.json();
    return data;
  },
};

export default RestAPIHandler;
