import { type ITSWData } from '../services/RequestHandler/types';
import { EScanModeTypes } from '../static/scanModesMetaData';

const formatDataToStructuredText = (data: any, scanMode: EScanModeTypes) => {
  const { result } = data;

  switch (scanMode) {
    case EScanModeTypes.TSW: {
      const { make, model, size, dot } = data as ITSWData;
      return `Tire Make: ${make || 'N/A'}\nTire Model: ${
        model || 'N/A'
      }\nTire Size: ${size || 'N/A'}\nDOT/TIN: ${dot || 'N/A'}`;
    }

    case EScanModeTypes.ID:
    case EScanModeTypes.UNIVERSAL_ID: {
      const {
        firstName,
        givenNames,
        lastName,
        surname,
        documentNumber,
        formattedDateOfExpiry,
        formattedDateOfBirth,
      } = result;
      return `First Name: ${firstName || givenNames || 'N/A'}\nLast Name: ${
        lastName || surname || 'N/A'
      }\nDate of Birth: ${formattedDateOfBirth || 'N/A'}\nDate of Expiry: ${
        formattedDateOfExpiry || 'N/A'
      }\nDocument Number: ${documentNumber || 'N/A'}`;
    }

    case EScanModeTypes.ANALOG_DIGITAL_SNR_BAR: {
      const { meter_value, barcodes, meter_snr } = result;
      const formattedReading = meter_value?.value || 'N/A';
      const formattedBarcode = barcodes?.[0]?.value || 'N/A';
      const formattedSNR = meter_snr?.value || 'N/A';
      return `Reading: ${formattedReading}\nBarcode: ${formattedBarcode}\nSNR: ${formattedSNR}`;
    }

    default: {
      const { reading, confidence } = result;
      const formattedReading = reading || 'N/A';
      const formattedConfidence = confidence || 'N/A';
      return `Reading: ${formattedReading}\nConfidence: ${formattedConfidence}%`;
    }
  }
};

export default formatDataToStructuredText;
