import { Box, Typography } from '@mui/material';
import { useContext } from 'react';
import { SidebarContext } from '../../../../contexts/SidebarContext';

interface IProps {
  handleCallback: (file: Blob) => void;
}

export default function SideBarImageBox({ handleCallback }: IProps) {
  const { selectedCard }: any = useContext(SidebarContext);

  const handleImageClick = async (e: React.MouseEvent<HTMLImageElement>) => {
    const target = e.target as HTMLImageElement;
    const url = target.src;
    const response = await fetch(url);
    const file = await response.blob();
    handleCallback(file);
  };

  return (
    <Box className="sidebar_image_box_container">
      <div className="sidebar_image_box">
        {selectedCard?.data.map((ele: any) =>
          Object.keys(ele).map((v) => {
            return (
              <Box className="sidebar_image_box_item" key={v}>
                <Typography variant="subtitle1" className="sidebar-imgIn">
                  <img
                    className="sidebar-image-box-img"
                    src={ele[v]}
                    id={ele[v]}
                    alt="dummy"
                    onClick={handleImageClick}
                  />
                </Typography>
              </Box>
            );
          }),
        )}
      </div>
    </Box>
  );
}
