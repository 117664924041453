import { useContext, useEffect, useState } from 'react';
import tswScanResultStyle from '../components/ScanResults/TSWScanResult/TSWScanResult.module.scss';
import barcodeScanResultStyle from '../components/ScanResults/BarcodeScanResult/BarcodeScanResult.module.scss';
import { SidebarContext } from '../contexts/SidebarContext';
import { EScanModeTypes } from '../static/scanModesMetaData';
import '../styles/Common.scss';
import '../styles/ViewResults.css';
import formatData from '../utils/formatData';

import IDAndUniversalIDScanResult from './ScanResults/IDAndUniversalIDScanResult/IDAndUniversalIDScanResult';
import MeterScanResult from './ScanResults/MeterScanResult/MeterScanResult';
import SNRBarScanResult from './ScanResults/SNRBarScanResult/SNRBarScanResult';
import TSWScanResult from './ScanResults/TSWScanResult/TSWScanResult';
import BarcodeScanResult from './ScanResults/BarcodeScanResult/BarcodeScanResult';
import ErrorBanner from './ErrorBanners/ErrorBanner';
import Modal from './modal/Modal';
import ErrorBannerTSW from './ErrorBanners/ErrorBannerTSW';
import TireRecallComponent from './TireRecall/TireRecall';

export enum ETabs {
  DATA = 'data',
  JSON = 'json',
}

const ViewResults = () => {
  const {
    file,
    dataApi,
    previewImage,
    error,
    selectedCard: { scanMode },
  } = useContext(SidebarContext) as any;
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete('isUGS');
    window.history.replaceState({}, '', `?${searchParams.toString()}`);
  }, []);

  const [activeTab, setActiveTab] = useState<ETabs>(ETabs.DATA);
  const [copied, setCopied] = useState(false);

  const handleTabClick = (tabName: ETabs) => {
    setActiveTab(tabName);
    setCopied(false);
  };

  const handleCopyClick = () => {
    const textToCopy = formatData(dataApi, activeTab, scanMode);
    navigator.clipboard
      .writeText(textToCopy.toString())
      .then(() => {
        setCopied(true);
      })
      .catch((error) => {
        console.error('Failed to copy text:', error);
      });
  };

  return (
    <>
      {error ? (
        scanMode === EScanModeTypes.TSW ? (
          <Modal open={true}>
            <ErrorBannerTSW file={file} errorMsg={dataApi.message} />
          </Modal>
        ) : (
          <Modal open={true}>
            <ErrorBanner file={file} />
          </Modal>
        )
      ) : (
        <div className="view-result-conatiner">
          <div
            className={`result-parent ${
              scanMode === EScanModeTypes.TSW
                ? tswScanResultStyle.resultParent
                : ''
            } ${
              scanMode === EScanModeTypes.BARCODE
                ? barcodeScanResultStyle.resultParent
                : ''
            }`}
          >
            <h3 className="view-result-head1">Scan Results</h3>
            <div className="result-buttons">
              <div>
                <button
                  onClick={() => {
                    handleTabClick(ETabs.DATA);
                  }}
                  className={`tab-button1 ${
                    activeTab === ETabs.DATA ? 'active' : ''
                  }`}
                >
                  Structured format
                </button>
                <button
                  onClick={() => {
                    handleTabClick(ETabs.JSON);
                  }}
                  className={`tab-button2 ${
                    activeTab === ETabs.JSON ? 'active' : ''
                  }`}
                >
                  Json format
                </button>
              </div>
              <div
                onClick={() => {
                  handleCopyClick();
                }}
                className="copy"
              >
                <div className="result-copy">{copied ? 'Copied!' : 'Copy'}</div>
                <img className="result-copy-svg" src="/copy.svg" alt="copy" />
              </div>
            </div>
            <div
              className={`notranslate result-box ${
                scanMode === EScanModeTypes.TSW
                  ? tswScanResultStyle.resultBox
                  : ''
              } ${
                scanMode === EScanModeTypes.BARCODE
                  ? barcodeScanResultStyle.resultBox
                  : ''
              }`}
            >
              {activeTab === ETabs.DATA ? (
                scanMode === EScanModeTypes.TSW ? (
                  <div>
                    <TireRecallComponent {...dataApi} />
                    <TSWScanResult {...dataApi} />
                  </div>
                ) : scanMode === EScanModeTypes.BARCODE ? (
                  <BarcodeScanResult {...dataApi} />
                ) : scanMode === EScanModeTypes.ID ||
                  scanMode === EScanModeTypes.UNIVERSAL_ID ? (
                  <IDAndUniversalIDScanResult {...dataApi} />
                ) : scanMode === EScanModeTypes.ANALOG_DIGITAL_SNR_BAR ? (
                  <SNRBarScanResult {...dataApi} />
                ) : (
                  <MeterScanResult {...dataApi} />
                )
              ) : (
                <pre className="notranslate">
                  {formatData(dataApi, activeTab, scanMode)}
                </pre>
              )}
              <div className="result-image">
                <img src={previewImage} alt="preview" />
              </div>
            </div>
          </div>
          <h3 className="view-result-head2">Scan Results</h3>
        </div>
      )}
    </>
  );
};

export default ViewResults;
