import css from './PreviewImage.module.css';
import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';

interface IProps {
  file: any;
  isLoadingMetrics: boolean;
  imageMetrics: {
    isBlurDetected?: boolean | undefined;
    isContrastLow?: boolean | undefined;
  };
  onProceed: () => void;
  onNewPicture: () => void;
}

export default function PreviewImage({
  file,
  isLoadingMetrics = true,
  imageMetrics,
  onProceed,
  onNewPicture,
}: IProps) {
  const [fileContent, setFileContent] = useState<any | undefined>(undefined);

  const getFileContent = () => {
    const reader = new FileReader();

    // convert img to dataurl so it can be processed for pre-processing
    reader.readAsDataURL(file);

    reader.onload = async function (event) {
      if (typeof event.target?.result === 'string') {
        const fileContent = event.target.result;
        setFileContent(fileContent);
      }
    };
  };

  useEffect(() => {
    getFileContent();
  }, []);

  if (isLoadingMetrics) {
    return (
      <div className={css.preprocessingWrapper}>
        Preprocessing Image. Please wait...
      </div>
    );
  }

  // These metrics can happen parallel (blurry & low contrast or Low contrast & edge detected ...etc)
  // Currently we use a priority list, so we only display 1 error at a time (current design limitation)
  // Prio 1: Blur, Prio 2: Low Contrast, Prio 3: Edge Detected
  return (
    <div className={css.wrapper}>
      {imageMetrics.isBlurDetected ? (
        <div className={css.descriptionWrapper}>
          <h1>Oops! Looks Like a Blurry Image</h1>
          <p>
            We couldn&#39;t detect the object. The image seems too blurry.
            Please snap a clearer photo for better recognition
          </p>
        </div>
      ) : null}
      {!imageMetrics.isBlurDetected && imageMetrics.isContrastLow ? (
        <div className={css.descriptionWrapper}>
          <h1>Oops! Low Contrast Detected in Your Image</h1>
          <p>
            Your image seems a bit dull due to low contrast. Try adjusting
            contrast levels or take another picture.
          </p>
        </div>
      ) : null}
      {!imageMetrics.isBlurDetected && !imageMetrics.isContrastLow ? (
        <div className={css.descriptionWrapper}>
          <h1>Oops! The tire is not entirely in clear sight</h1>
          <p>
            The image you have submitted shows only parts of a tire or
            doesn&#39;t contain a tire. Make sure that the whole tire is visible
            in the image and that all relevant information is clearly
            identifiable.
          </p>
        </div>
      ) : null}
      <div>
        {!fileContent ? (
          <div className={css.inputImagePlaceholder}>Loading image...</div>
        ) : (
          <img className={css.inputImage} src={fileContent} alt="Your Image" />
        )}
      </div>
      {imageMetrics.isBlurDetected ? (
        <div className={css.buttonWrapper}>
          <button
            onClick={() => {
              onNewPicture();
            }}
            className={css.primaryButton}
          >
            <Typography variant="subtitle2">
              <strong>Take a new picture</strong>
            </Typography>
          </button>
          <button
            onClick={() => {
              onProceed();
            }}
            className={css.secondaryButton}
          >
            <Typography variant="subtitle2">
              <strong>Proceed with this image</strong>
            </Typography>
          </button>
        </div>
      ) : null}
      {!imageMetrics.isBlurDetected && imageMetrics.isContrastLow ? (
        <div className={css.buttonWrapper}>
          <button
            onClick={() => {
              onNewPicture();
            }}
            className={css.primaryButton}
          >
            <Typography variant="subtitle2">
              <strong>Try again</strong>
            </Typography>
          </button>
        </div>
      ) : null}
    </div>
  );
}
