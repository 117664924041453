import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import '../styles/Navbar.css';
import PersistentDrawerRight from './drawer/drawer';

const Navbar = () => {
  const handleLogoClick = () => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete('scanmode');
    window.history.replaceState({}, '', `?${searchParams.toString()}`);
    window.location.reload();
  };

  return (
    <AppBar className="navbar-main" elevation={1}>
      <Toolbar className="navbar-toolbar">
        <Box className="navbar-box-main">
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a
            onClick={handleLogoClick}
            className="navbar-box-item"
            target="_blank"
          >
            <img
              className="navbar-logo-desktop"
              src="https://anyline.com/app/themes/towa-theme/dist/img/anyline-logo.svg"
              alt="logo"
            />
            <img
              className="navbar-logo-mobile"
              src="https://anyline.com/app/themes/towa-theme/dist/img/anyline-small-pos.svg"
              alt="logo"
            />
          </a>
          <div>
            <Box>
              <Typography className="navbar-text-large">
                Welcome to Anyline <span>Cloud API</span>
              </Typography>
              <Typography className="navbar-text-small">
                Try our entreprise-grade data capture solutions for yourself
              </Typography>
            </Box>
          </div>
          <div className="drawer-container">
            <PersistentDrawerRight />
          </div>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
