import { EScanModeTypes } from '../../../static/scanModesMetaData';
import style from '../ScanResults.module.scss';
import getConfidence from '../utils/getConfidence';
import getConfidenceCSSClass from '../utils/getConfidenceCSSClass';
import componentStyle from './IDAndUniversalIDScanResult.module.scss';

const IDAndUniversalIDScanResult: React.FC<any> = ({ result }: any) => {
  const {
    firstName,
    lastName,
    surname,
    givenNames,
    formattedDateOfBirth,
    formattedDateOfExpiry,
    documentNumber,
    fieldConfidences,
  } = result;

  const {
    firstName: firstNameConfidence,
    lastName: lastNameConfidence,
    surname: surnameConfidence,
    givenNames: givenNamesConfidence,
    formattedDateOfBirth: formattedDateOfBirthConfidence,
    formattedDateOfExpiry: formattedDateOfExpiryConfidence,
    documentNumber: documentNumberConfidence,
  } = fieldConfidences;

  const renderDetails = (
    label: string,
    labelValue: string | null,
    confidence: number,
    testId: string,
  ) => {
    const confidenceCSSStyle = getConfidenceCSSClass(
      confidence,
      EScanModeTypes.ID,
    );

    return (
      <>
        <span>
          <span className={componentStyle.label}>{label}:&nbsp;</span>
          <span
            className={`${componentStyle.value} ${style.blue}`}
            data-testid={`IDAndUniversalIDScanResult-${testId}-reading`}
          >
            {labelValue || 'N/A'}
          </span>
        </span>
        <br />
        <span>
          <span className={componentStyle.label}>Confidence:&nbsp;</span>
          <span
            className={confidenceCSSStyle}
            data-testid={`IDAndUniversalIDScanResult-${testId}-confidence`}
          >
            {getConfidence(confidence, EScanModeTypes.ID)}
          </span>
        </span>
      </>
    );
  };

  return (
    <div className={componentStyle.wrapper}>
      {renderDetails(
        'First Name',
        firstName || givenNames,
        firstNameConfidence || givenNamesConfidence,
        'firstName',
      )}
      <br />
      {renderDetails(
        'Last Name',
        lastName || surname,
        lastNameConfidence || surnameConfidence,
        'lastName',
      )}
      <br />
      {renderDetails(
        'Date of Birth',
        formattedDateOfBirth,
        formattedDateOfBirthConfidence,
        'formattedDateOfBirth',
      )}
      <br />
      {renderDetails(
        'Date of Expiry',
        formattedDateOfExpiry,
        formattedDateOfExpiryConfidence,
        'formattedDateOfExpiry',
      )}
      <br />
      {renderDetails(
        'Document Number',
        documentNumber,
        documentNumberConfidence,
        'documentNumber',
      )}
    </div>
  );
};

IDAndUniversalIDScanResult.defaultProps = {
  result: {
    firstName: '',
    lastName: '',
    surname: '',
    givenNames: '',
    formattedDateOfBirth: '',
    formattedDateOfExpiry: '',
    documentNumber: '',
    fieldConfidences: {
      firstName: -1,
      lastName: -1,
      surname: -1,
      givenNames: -1,
      formattedDateOfBirth: -1,
      formattedDateOfExpiry: -1,
      documentNumber: -1,
    },
  },
};

export default IDAndUniversalIDScanResult;
