import css from './ErrorBanner.module.css';
import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';

interface IProps {
  file: any;
}

export default function ErrorBanner({ file }: IProps) {
  const [fileContent, setFileContent] = useState<any | undefined>(undefined);

  const getFileContent = () => {
    const reader = new FileReader();
    try {
      // convert img to dataurl so it can be processed for pre-processing
      reader.readAsDataURL(file);

      reader.onload = async function (event) {
        if (typeof event.target?.result === 'string') {
          const fileContent = event.target.result;
          setFileContent(fileContent);
        }
      };
    } catch {
      setFileContent(undefined);
    }
  };

  useEffect(() => {
    getFileContent();
  }, []);

  return (
    <div className={css.wrapper}>
      <div className={css.descriptionWrapper}>
        <h1>Oops! Image glitch</h1>
        <p>
          It seems like there was an error while reading the image.<br></br>
          Could you please give another image a try?
        </p>
      </div>
      <div>
        {!fileContent ? (
          <div className={css.inputImagePlaceholder}>Loading image...</div>
        ) : (
          <img className={css.inputImage} src={fileContent} alt="Your Image" />
        )}
      </div>
      <div className={css.buttonWrapper}>
        <button
          onClick={() => {
            window.location.reload();
          }}
          className={css.primaryButton}
        >
          <Typography variant="subtitle2">Try again</Typography>
        </button>
      </div>
    </div>
  );
}
