import { EScanModeTypes } from '../../../static/scanModesMetaData';
import style from '../MeterScanResult/MeterScanResult.module.scss';
import getConfidence from '../utils/getConfidence';
import getConfidenceCSSClass from '../utils/getConfidenceCSSClass';

const SNRBarScanResult: React.FC<any> = ({ result }: any) => {
  const {
    meter_value: { value: meterValue, confidence: meterValueConfidence },
    barcodes,
    meter_snr: { value: meterSNRValue, confidence: meterSNRValueConfidence },
  } = result;

  // check if barcode list is empty,
  // currently we allow results with empty barcode part
  const barcodeValue =
    barcodes.length > 0
      ? barcodes.map((barcode: { value: any }) => barcode.value)
      : [];

  const meterValueConfidenceCSSStyle = getConfidenceCSSClass(
    meterValueConfidence,
    EScanModeTypes.ANALOG_DIGITAL_SNR_BAR,
  );

  const meterSNRValueConfidenceCSSStyle = getConfidenceCSSClass(
    meterSNRValueConfidence,
    EScanModeTypes.ANALOG_DIGITAL_SNR_BAR,
  );

  return (
    <div>
      <span>Reading</span> <br />
      <span className={style.reading}>{meterValue || 'N/A'}</span>
      <br />
      <span>Confidence: </span>
      <span className={meterValueConfidenceCSSStyle}>
        <b>
          {getConfidence(
            meterValueConfidence,
            EScanModeTypes.ANALOG_DIGITAL_SNR_BAR,
          )}
        </b>
      </span>
      <br />
      <span>Barcode Value: </span>
      <span>
        <b>{barcodeValue.length ? barcodeValue : 'N/A'}</b>
      </span>
      <br />
      <span>SNR Value: </span>
      <span>
        <b>{meterSNRValue || 'N/A'}</b>
      </span>
      <br />
      <span>Confidence: </span>
      <span className={meterSNRValueConfidenceCSSStyle}>
        <b>
          {getConfidence(
            meterSNRValueConfidence,
            EScanModeTypes.ANALOG_DIGITAL_SNR_BAR,
          )}
        </b>
      </span>
    </div>
  );
};

SNRBarScanResult.defaultProps = {
  result: {
    meter_value: {
      value: '',
      confidence: -1,
    },
    meter_snr: {
      value: '',
      confidence: -1,
    },
    barcodes: [
      {
        value: '',
      },
    ],
  },
  sdkVersion: '',
};

export default SNRBarScanResult;
