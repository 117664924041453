import React, { useState } from 'react';
import Icon from '../../../assets/icons';
import { type ITSWData } from '../../../services/RequestHandler/types';

import style from '../ScanResults.module.scss';
import tswStyle from './TSWScanResult.module.scss';

const TSWScanResult: React.FC<ITSWData> = ({ make, model, size, details }) => {
  const [imageExists, setImageExists] = useState(true);

  const renderLogos = (make: string) => {
    const handleError = () => {
      setImageExists(false);
    };

    const imagePath = `./images/tsw-logos/${make.toUpperCase()}.png`;

    if (!imageExists) return null;
    return (
      <div className={tswStyle.readingDetailsImage}>
        <img src={imagePath} alt={`${make} logo`} onError={handleError} />
      </div>
    );
  };

  const renderTSWOptionalDetails = (text: string) => {
    if (!text) return null;
    if (text !== 'Tire Make' && text !== 'Tire Size') return null;
    if (text === 'Tire Make' && !details?.tireAgeInYearsRoundedDown)
      return null;
    if (text === 'Tire Make') {
      return (
        <div className={tswStyle.readingAdditionalInfo}>
          <div className={tswStyle.readingKey}>Age:&nbsp;</div>
          <div
            className={`${tswStyle.readingValue} ${style.blue}`}
            data-testid={`TSWScanResult-Age`}
          >
            {details.tireAgeInYearsRoundedDown} y/o
          </div>
        </div>
      );
    }

    return (
      <>
        {details?.speedRating && (
          <div className={tswStyle.readingAdditionalInfo}>
            <div className={tswStyle.readingKey}>Speed:&nbsp;</div>
            <div
              className={`${tswStyle.readingValue} ${style.blue}`}
              data-testid={`TSWScanResult-SpeedRating`}
            >
              {details.speedRating}
              {details.speedRatingLimit && (
                <>
                  {' '}
                  <span>{`(${details.speedRatingLimit.kmh} kmh - ${details.speedRatingLimit.mph} mph)`}</span>
                </>
              )}
            </div>
          </div>
        )}
        {details?.loadIndex && (
          <div className={tswStyle.readingAdditionalInfo}>
            <div className={tswStyle.readingKey}>Load:&nbsp;</div>
            <div
              className={`${tswStyle.readingValue} ${style.blue}`}
              data-testid={`TSWScanResult-LoadIndex`}
            >
              {details.loadIndex}
              {details.loadIndexLimit && (
                <>
                  {' '}
                  <span>{`(${details.loadIndexLimit.kg} kg - ${details.loadIndexLimit.lb} lb)`}</span>
                </>
              )}
            </div>
          </div>
        )}
      </>
    );
  };
  const renderTSWResult = (reading: string | null, text: string) => {
    return (
      <div className={tswStyle.readingDetails}>
        {/* Text with Checkmark */}
        <div className={tswStyle.readingDetailsCheckMark}>
          <div>
            <Icon.Checkmark />
          </div>
          <div>{text}</div>
        </div>
        <div className={tswStyle.readingDetailsInd}>
          <div className={tswStyle.readingKey}>Reading:&nbsp;</div>
          <div
            className={`${tswStyle.readingValue} ${style.blue}`}
            data-testid={`TSWScanResult-${text}`}
          >
            {reading || 'N/A'}
          </div>
        </div>
        {renderTSWOptionalDetails(text)}
      </div>
    );
  };

  return (
    <div className={tswStyle.wrapper}>
      {/* Make Logos */}
      {make && renderLogos(make)}
      {/* Manufacturer */}
      {renderTSWResult(make, 'Tire Make')}
      {/* Model */}
      {renderTSWResult(model, 'Tire Model')}
      {/* Size */}
      {renderTSWResult(size, 'Tire Size')}
    </div>
  );
};

TSWScanResult.defaultProps = {
  make: null,
  model: null,
  size: null,
  dot: null,
  fieldConfidences: {
    make: null,
    model: null,
    size: null,
    dot: null,
  },
  details: {
    tireAgeInYearsRoundedDown: null,
    loadIndex: null,
    speedRating: null,
  },
};

export default TSWScanResult;
