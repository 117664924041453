import { EScanModeTypes } from '../../../static/scanModesMetaData';

const getConfidence = (
  confidence: number | null | undefined,
  scanMode: EScanModeTypes,
): string => {
  if (confidence === null || confidence === undefined || confidence === -1)
    return 'N/A';
  if (scanMode === EScanModeTypes.TSW) {
    return `${confidence * 100}%`;
  }
  return `${confidence}%`;
};

export default getConfidence;
