import React from 'react';
import '../styles/StepProgress.css';

interface StepProgressProps {
  activeStep: number;
}

const StepProgress: React.FC<StepProgressProps> = ({ activeStep }) => {
  const stepCount = 3;

  return (
    <div className="step-progress-main">
      <div className="step-progress">
        {[...Array(stepCount)].map((_, index) => {
          const stepNumber = index + 1;
          const isActive = stepNumber === activeStep;
          const isCompleted = stepNumber < activeStep;
          return (
            <React.Fragment key={index}>
              <div
                className={`step ${isCompleted ? 'completed' : ''} ${
                  isActive ? 'active' : ''
                }`}
              >
                {stepNumber}
              </div>
              {index < stepCount - 1 && (
                <div
                  className={`line ${isCompleted ? 'completed' : ''} ${
                    isActive ? 'active' : ''
                  }`}
                />
              )}
            </React.Fragment>
          );
        })}
      </div>
      <div className="step-progress-bottom">
        <div className="step-label">Select Mode</div>
        <div className="step-label">Upload and Read Image</div>
        <div className="step-label">View Results</div>
      </div>
    </div>
  );
};

export default StepProgress;
